import { PROFILE_ADMIN } from "api/apiConstant";
import BaseApi from "api/baseApi";

class ProfileAdminApi extends BaseApi {
  getServiceDetail() {
    return this.get(PROFILE_ADMIN);
  }

  updateProfile(payload: any) {
    this.put(PROFILE_ADMIN, payload);
  }
}
export default ProfileAdminApi;
