import { ADMIN_NURSING_CARE_HISTORIES } from "api/apiConstant";
import BaseApi from "api/baseApi";
import { MONTH_FORMAT } from "constant/date";
import dayjs from "dayjs";
import queryString from "query-string";

class NursingCareRecordApi extends BaseApi {
  getListPatientRecord = (idToken: any, param: any, role: any) => {
    const qs = queryString.stringify(param, { skipNull: true });
    const url = `/${role}/patients?${qs}`;
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    return this.get(url, config);
  };

  getPatientService = (idToken: any, param: any, role: any) => {
    const qs = queryString.stringify(param, { skipNull: true });
    const url = `/${role}/services?${qs}`;
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    return this.get(url, config);
  };

  getServiceType = (idToken: any, param: any, role: any) => {
    const qs = queryString.stringify(param, { skipNull: true });
    const url = `/${role}/service_types?${qs}`;
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    return this.get(url, config);
  };

  getStaffActive = (idToken: any, param: any, role: any) => {
    const qs = queryString.stringify(param, { skipNull: true });
    const url = `/${role}/nursing_staffs?${qs}`;
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    return this.get(url, config);
  };

  resetSchedule = async (schedule_date_ids: string[] | number[]) => {
    return await this.post("admin/shift_managements/clear-schedule-date", { schedule_date_ids })
  } 

  getNursingCareHistory = (idToken: any, param: any, role: any) => {
    const qs = queryString.stringify(param, { skipNull: true });
    const url = `/${role}/nursing_care_histories?${qs}`;
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    return this.get(url, config);
  };

  getDetailNursingCare = (idToken: any, param: any, role: any) => {
    const qs = queryString.stringify(
      { schedule_date_id: param?.schedule_date_id, schedule_date: param?.date },
      { skipNull: true }
    );
    const url = `/${role}/nursing_care_histories/schedule_date?${qs}`;
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    return this.get(url, config);
  };

  getSchedule = (idToken: any, param: any, role: any) => {
    const url = `${role}/schedules/${param}`;
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    return this.get(url, config);
  };

  createNursingCare = (idToken: any, param: any, role: any) => {
    const url = `/${role}/nursing_care_histories`;
    // const config = {
    //   headers: { Authorization: `Bearer ${idToken}` },
    // };
    return this.post(url, param);
  };

  updateNursingCare = (idToken: any, param: any, role: any) => {
    const url = `/${role}/nursing_care_histories`;
    // const config = {
    //   headers: { Authorization: `Bearer ${idToken}` },
    // };
    return this.put(url, param);
  };

  getNursingCareHistoryCalendar<T>(params: { date: string }) {
    return this.get<T>(ADMIN_NURSING_CARE_HISTORIES + `/calendar`, params);
  }

  getNursingCareHistoryChanges = (params: any) => {
    const qs = queryString.stringify(params, { skipNull: true });
    const url = `/admin/nursing_care_history_changes?${qs}`;

    return this.get(url);
  };

  getNursingCareDetail = (params: any) => {
    const qs = queryString.stringify(params, { skipNull: true });
    const url = `/admin/nursing_care_history_changes/detail?${qs}`;

    return this.get(url);
  };

  pdfServiceDelivery = (payload: any) => {
    const url = `/admin/export_pdf/service-delivery`;
    return this.post(url, { ...payload });
  };

  pdfPaymentReceiptNotice = (payload: any) => {
    const url = `/admin/export_pdf/payment-receipt-notice`;
    return this.post(url, { ...payload });
  };
}

const nursingCareRecordApi = new NursingCareRecordApi();

export default nursingCareRecordApi;
