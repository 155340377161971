import { Stack } from "@chakra-ui/layout";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { StyleGrid, StyleGridItem } from "component/StyledComponent";
import "./dailyform.scss";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";

export const styleRadioProps = {
  bg: "brand2.500 !important",
  width: "65% !important",
  height: "65% !important",
};

const RadioForm = ({
  title,
  rdTit1,
  rdTit2,
  value,
  setValue,
  col = 5,
  rdVal1,
  rdVal2,
  disable = false,
  color = "#555555",
  fontSize = "16px",
  error,
  onChange,
  ...rest
}: any) => {
  return (
    <StyleGrid
      className="scroll-mt-14"
      templateColumns={`repeat(${col}, 1fr)`}
      gap={6}
      {...(rest && rest)}
    >
      <StyleGridItem colSpan={1}>
        <p style={{ color: color, fontSize: fontSize }}>{title}</p>
      </StyleGridItem>
      <StyleGridItem colSpan={col - 1}>
        <FormControl isInvalid={error && error.length > 0}>
          <RadioGroup
            colorScheme="white"
            style={{ fontSize: fontSize }}
            name="division"
            onChange={(value) => {
              setValue(value)
              onChange && onChange()
            }}
            value={value}
          >
            <Stack direction="row">
              <Radio
                _before={styleRadioProps}
                isDisabled={disable}
                value={rdVal1 || rdTit1}
                borderColor={`${
                  value === rdTit1 ? "var(--header-color)" : "#000"
                }`}
              >
                <p style={{ fontSize: fontSize }}>{rdTit1}</p>
              </Radio>
              <Radio
                _before={styleRadioProps}
                value={rdVal2 || rdTit2}
                borderColor={`${
                  value === rdTit2 ? "var(--header-color)" : "#000"
                }`}
                isDisabled={disable}
              >
                <p style={{ fontSize: fontSize }}>{rdTit2}</p>{" "}
              </Radio>
            </Stack>
          </RadioGroup>
          <FormErrorMessage>{error && error}</FormErrorMessage>
        </FormControl>
        {/* {error && <span className='text-red-600' >{error}</span>} */}
      </StyleGridItem>
    </StyleGrid>
  );
};

export default RadioForm;
