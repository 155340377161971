import styled from "@emotion/styled";
import { Table } from "antd";


export const TablePDF = styled(Table)`
  .ant-table-container {
    border-top: 1px solid #010a021f !important;
    border-inline-start: unset !important;
  }

  .ant-table-cell:first-child {
    border-inline-start: 1px solid #010a021f;
  }

  tr td,
  tr th {
    border-color: #010a021f !important;
    padding: 0 !important;
  }

  @media print {
    @page {
      padding: 6mm 0;
    }
    .break-inside {
      break-inside: avoid;
    }
  }
`;

const TableStyled = styled(TablePDF)`
 
  .disabled {
    background: #cccccc !important;
    &:hover {
      background: #cccccc;
    }
  }
`;


export default TableStyled;
