import styled from "@emotion/styled";
import { Form } from "antd";

const StyleFormFilterHistory = styled(Form)`
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input,
  .ant-select-selector {
    border-color: #d9d9d9 !important;
  }
`;

export default StyleFormFilterHistory;
