import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "utils/classnames";
import { Styled } from "./styled";
import { DatePicker } from "antd";
import { ChevronLeft, ChevronRight } from "assets/icons";
import { CURRENT_DATE_KEY } from "component/MonthChanger";
import { DATE_FORMAT } from "constant/date";

interface DateChangerProps {
  onChange?: (date: Dayjs) => void;
  isDisabledFuture?: boolean;
  className?: string;
  format?: string;
  nextIcon?: React.ReactNode;
  preIcon?: React.ReactNode;
  type?:  "days" | "months"
  picker?:  "month" | "year" | "week" | "quarter"
}

const DateChanger = (props: DateChangerProps) => {
  const { onChange, isDisabledFuture, type = "days", picker } = props;
  const [searchParams] = useSearchParams();
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());

  const isDisabledNextButton = useMemo(
    () => currentDate.date() === dayjs().date() && isDisabledFuture,
    [currentDate]
  );

  const handleNext = () => {
    if (isDisabledNextButton) {
      return;
    }

    const newDate = currentDate?.add(1, type);
    setCurrentDate(newDate);
    onChange?.(newDate);
  };

  const handlePrev = () => {
    const newDate = currentDate.add(-1, type);
    setCurrentDate(newDate);
    onChange?.(newDate);
  };

  useEffect(() => {
    const newDate = dayjs(searchParams.get(CURRENT_DATE_KEY)).isValid()
      ? dayjs(searchParams.get(CURRENT_DATE_KEY))
      : dayjs();
    setCurrentDate(newDate);
  }, [searchParams.get(CURRENT_DATE_KEY)]);

  return (
    <>
      <Styled className={cn("flex justify-center py-2", props.className)}>
        <button onClick={handlePrev} className="p-3">
          {props.nextIcon || <ChevronLeft />}
        </button>
        <DatePicker
          value={currentDate}
          format={props.format || DATE_FORMAT}
          inputReadOnly={true}
          allowClear={false}
          picker={picker}
          disabledDate={(current) => {
            return isDisabledFuture ? current > dayjs() : false;
          }}
          onChange={(date) => {
            if (date) {
              setCurrentDate(dayjs(date));
              onChange?.(dayjs(date));
            }
          }}
        />
        <button
          onClick={handleNext}
          className={cn(
            "p-3",
            isDisabledNextButton && "opacity-0 pointer-events-none"
          )}
        >
          {props.preIcon || <ChevronRight />}
        </button>
      </Styled>
    </>
  );
};

export default DateChanger;
