// Common
export const LOGIN_CLIENT = "/client/login";
export const LOGOUT = "/logout";
export const ME = "/me";
export const SHIFT_MANAGEMENT_LIST_DATA_BY_MONTH =
  "/nursing_shift_manages/summary";
export const SHIFT_MANAGEMENT_LIST_STAFF = "/nursing_shift_manages/list_staff";
export const GET_ALL_USING_PATIENTS = "/patients";
export const GET_ALL_SERVICES = "/services";
export const GET_DETAIL_SERVICES = "/service_types";
export const GET_ALL_STAFFS = "/schedules/nursing_staffs";
export const REGISTER_SHIFT = "/schedules";
export const GET_WEEK_SCHEDULE = "/nursing_shift_manages/summary";
export const GET_WEEK_SCHEDULE_STAFF = "/nursing_shift_manages/summary";
export const NURSING_SHIFT_MANAGE = "/nursing_shift_manages";
export const AUTO_SHIFTS = "/shift_managements/auto_shifts";
export const SCHEDULE_DATES = "/schedule_dates";

// Staff
export const LOGIN_STAFF = "/staff/login";
export const NURSING_MANAGEMENT_STAFF = "staff/nursing_staffs";
export const STAFF_CONTACT = "/staff/rooms";
export const BOARD_STAFF = "/staff/notifications";
export const PATIENT_STAFF = "/staff/patients/all";
export const STAFF_ROUTINES = "/staff/routines";
export const STAFF_NOTICE = "/staff/notifications";
export const STAFF_NOTICE_GROUP = "/staff/notifications/group";
export const STAFF_DASHBOARD = "/staff/dashboard";
export const STAFF_NURSING_CARE_HISTORIES_CALENDAR =
  "/staff/nursing_care_histories/calendar";
export const STAFF_NURSING_CARE_HISTORIES = "/staff/nursing_care_histories";
export const STAFF_EVALUE_TWOS = "/staff/evalue_twos";
export const STAFF_EVALUE_ONES = "/staff/evalue_ones";
export const STAFF_SHIFT_MANAGEMENTS = "/staff/shift_managements";


// Home system - Admin
export const LOGIN_HS_ADMIN = "/admin_home_system/login";
export const DASHBOARD_HS_ADMIN = "/admin_home_system/dashboard";
export const NOTICE_HS_ADMIN = "/admin_home_system/notifications";
export const ROOM_HS_ADMIN = "/admin_home_system/rooms";
export const PATIENT_HS_ADMIN = "/admin_home_system/patients";
export const NURSING_HS_ADMIN = "/admin_home_system/staff_home_system";

// Home system - Staff
export const LOGIN_HS_STAFF = "/staff_home_system/login";
export const DASHBOARD_HS_STAFF = "/staff_home_system/dashboard";
export const NOTICE_HS_STAFF = "/staff_home_system/notifications";
export const ROOM_HS_STAFF = "/staff_home_system/rooms";
export const PATIENT_HS_STAFF = "/staff_home_system/patients";
export const NURSING_HS_STAFF = "/staff_home_system/user_home_system";

// Admin
export const ADMIN_DASHBOARD = "/admin/dashboard";
export const ADMIN_EVALUE_ONES = "/admin/evalue_ones";
export const ADMIN_EVALUE_TWOS = "/admin/evalue_twos";
export const LOGIN_ADMIN = "/admin/login";
export const BOARD_ADMIN = "/admin/notifications";
export const PATIENT_ADMIN = "/admin/patients";
export const CONTACT = "/admin/rooms";
export const NURSING_MANAGEMENT_ADMIN = "admin/nursing_staffs";
export const FACILITY_REGISTRATION = "/admin/equipment_services";
export const SHIFT_REGISTRATION = "/admin/shift_registrations";
export const TREATMENT = "/admin/treatment_improvements";
export const SERVICE_CONTENT = "/admin/service_types";
export const SERVICE_REGISTRATION = "/admin/services";
export const PROFILE_ADMIN = "/admin/system_management_profiles";
export const ADMIN_SHIFT_MANAGEMENTS = "/admin/shift_managements";
export const ADMIN_SHIFT_MANAGEMENTS_SHIFT_STATUS = "/admin/shift_managements/update-shift-status";
export const ADMIN_NURSING_CARE_HISTORIES = "/admin/nursing_care_histories";

export const REST_API_CUSTOMER = {
  CREATE_CUSTOMER_ASSESSMENT_SHEET_ONE: {
    uri: "/evalue_ones",
    method: "POST",
  },
  GET_CUSTOMER_ASSESSMENT_SHEET_ONE_DETAIL: {
    uri: "/evalue_ones/:id",
    method: "GET",
  },
  UPDATE_CUSTOMER_ASSESSMENT_SHEET_ONE: {
    uri: "/evalue_ones/:id",
    method: "PUT",
  },
};
